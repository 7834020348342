import React, {useEffect, useRef} from "react";
import {gsap} from "gsap";

function TableHeader(props) {
    const triangle = useRef(null);

    useEffect(() => {
        if (triangle.current) {
            if (props.direction === "ASC") {
                gsap.to(triangle.current, .25, {scaleY: 1});
            } else {
                gsap.to(triangle.current, .25, {scaleY: -1});
            }
        }
        // eslint-disable-next-line
    }, [props]);

    return (
        <>
            <div className="row header">
                {
                    props.fields.map((field, index) =>
                        <div onClick={() => props.onClick(field.className)}
                             key={index}
                             className={"cell sortable " + field.className}
                        >
                            {field.label}
                            {props.order === field.className &&
                                <div className="triangle" ref={triangle}/>
                            }
                        </div>)
                }
            </div>
        </>
    );
}

export default TableHeader;