import React, {useEffect, useState} from 'react';
import './css/app.css';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Bestaetigen from "pages/Bestaetigen";
import Login from "components/Login";

function App() {
    const [webfont, setWebfont] = useState(false);
    const [status, setStatus] = useState("");

    useEffect(() => {
        import('webfontloader').then(WebFont =>
            WebFont.load({

                custom: {
                    families: ['EuropaGroNr2SH-Ita', 'EuropaGroNr2SH-Rom', 'EuropaGroNr2SH-Ult', 'EuropaGroNr2SH-Bol']
                },
                active: function () {
                    setWebfont(true);
                }
            })
        )

        checkLogin();
    }, []);

    async function checkLogin() {
        let endpoint = process.env.REACT_APP_API_ENDPOINT;
        endpoint += "/user/check";

        let options = {
            credentials: 'include'
        };
        try {
            const response = await fetch(endpoint, options);
            let json = await response.json();
            onCheck(json);
        } catch (error) {
            console.log(error);
        }
    }

    const onCheck = (data) => {
        setStatus(data.status);
    }

    return (
        <>
            {webfont &&
                <>
                    <Header/>
                    {
                        status === 'user' &&
                        <Routes>
                            <Route path="/" element={<Home/>}/>

                            <Route path="pruefen/:id" exact element={<Bestaetigen/>}/>
                        </Routes>
                    }
                    {
                        status === 'guest' &&
                        <div className="page page-login">
                            <div className="container">
                                <Login handleUserHasAuthenticated={checkLogin}/>
                            </div>
                        </div>
                    }

                </>
            }
        </>
    );
}

export default App;
