import React, {useEffect, useState} from "react";
import Table from "components/table/Table";
import {format} from "date-fns";

function Home(props) {
    let n = localStorage.getItem('numItems') ? parseInt(localStorage.getItem('numItems')) : 10;
    if (n < 1) {
        n = 10
    }
    const [numItems, setNumItems] = useState(n);
    const [participants, setParticipants] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState("createdAt");
    const [direction, setDirection] = useState("ASC");

    let endpoint = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        load();
    }, [page, numItems, order, direction]);

    async function load() {
        let url = endpoint + "/admin/all";

        let formData = new FormData();
        formData.append("page", page + 1);
        formData.append("numItems", numItems);
        formData.append("order", order);
        formData.append("direction", direction);

        let options = {
            credentials: 'include',
            method: "POST",
            body: formData
        };
        try {
            const response = await fetch(url, options);
            let json = await response.json();
            onData(json);
        } catch (error) {
            console.log(error);
        }
    }

    const onUpdate = (p, n, o, d) => {
        localStorage.setItem('numItems', n);
        setNumItems(n);
        setPage(p);
        setOrder(o);
        setDirection(d);
    };

    const renderStatus = (item) => {
        switch (item.status) {
            case 'new':
                return "Neu";
            case 'confirmed':
                return "Bestätigt";
            case 'approved':
                return <span className="freigegeben">Freigegeben</span>;
            case 'deleted':
                return <span className="geloescht">Gelöscht</span>;
            case 'offline':
                return <span className="offline">Offline</span>;
        }
    }

    const makeTableHeader = () => {
        let header = [
            {className: 'createdAt', label: 'Datum'},
            {className: 'id', label: 'ID'},
            {className: 'firstname', label: 'Vorname'},
            {className: 'lastname', label: 'Nachname'},
            {className: 'status', label: 'Status'},
        ];
        return header;
    };

    const makeTableItems = () => {
        let items = [];
        participants.map((item) =>
            items.push(
                {
                    obj: item,
                    link: '/pruefen/' + item.id,
                    fields: [
                        {className: 'createdAt', label: format(new Date(item.createdAt.date), 'dd.MM.yyyy', {})},
                        {className: 'id', label: item.id},
                        {className: 'firstname', label: item.firstname},
                        {className: 'lastname', label: item.lastname},
                        {className: 'status', label: renderStatus(item)},
                    ]
                }
            )
        );
        return items;
    };

    const onData = (data) => {
        setParticipants(Object.values(data.list));
        setTotalItems(data.totalItems);
    };

    return (
        <>
            <div className="page">
                <div className="container">
                    <h1>Alle Beiträge</h1>

                    {totalItems &&
                        <Table
                            page={page}
                            order={order}
                            header={makeTableHeader()}
                            items={makeTableItems()}
                            totalItems={totalItems}
                            numItems={numItems}
                            onUpdate={onUpdate}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default Home;