import React from "react";
import NaviItem from "./NaviItem";
import {Link} from "react-router-dom";

function Header(props) {
    return (
        <>
            <div className="header">
                <div className="inner">
                    <Link to="/" className="navi-item home">
                        Die Deutschen des<br/><span>21.</span> Jahrhunderts
                    </Link>

                    <NaviItem href="/" label="Alle Beiträge"/>
                </div>
            </div>
        </>
    )
}

export default Header;