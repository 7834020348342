import React, {useEffect, useRef, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import TablePagination from "components/table/TablePagination";

function Table(props) {
    const [numItems, setNumItems] = useState(props.numItems ? props.numItems : 20);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState(props.order);
    const [direction, setDirection] = useState("ASC");

    const didMount = useRef(false);

    const renderRows = () => {
        let rows = [];
        let begin = 0;
        if (props.local) {
            begin = page * numItems;
        }
        let end = page * numItems + numItems;
        if (end > props.items.length || !props.local) {
            end = props.items.length;
        }
        for (let i = begin; i < end; i++) {
            const item = props.items[i];
            const row = <TableRow key={item.obj.id} obj={item.obj} fields={item.fields} onClick={props.onClick} link={item.link} header={props.header}/>;
            rows.push(row);
        }
        return rows;
    };

    useEffect(() => {
        if (didMount.current) {
            //if (!props.local) {
            props.onUpdate(page, numItems, order, direction);
            //}
        } else {
            didMount.current = true;
        }
        // eslint-disable-next-line
    }, [page, numItems, order, direction]);

    useEffect(() => {
        if (!props.local) {
            setPage(props.page);
        }
        // eslint-disable-next-line
    }, [props.page]);

    const onUpdateNumItems = (num) => {
        setNumItems(num);
    };

    const onUpdatePage = (p) => {
        setPage(p);
    };

    const onUpdateOrder = (o) => {

        if (o === order) {
            if (direction === "ASC") {
                setDirection("DESC");
            } else {
                setDirection("ASC");
            }
        } else {
            setDirection("ASC");
        }

        setPage(0);
        setOrder(o);
    };

    return (
        <div className="table-wrapper">
            <div className="table">
                {props.header &&
                    <TableHeader fields={props.header} sortable={true} onClick={onUpdateOrder} order={order} direction={direction}/>
                }
                {
                    renderRows()
                }
            </div>
            <TablePagination
                numItems={numItems}
                page={page}
                totalPages={Math.ceil(props.totalItems / numItems)}
                totalItems={props.totalItems}
                onUpdateNumItems={onUpdateNumItems}
                onUpdate={onUpdatePage}
            />
        </div>
    );
}

export default Table;