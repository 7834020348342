import React, {useState, useEffect, useRef} from "react";

function TablePagination(props) {
    const [numItemsInput, setNumItemsInput] = useState(props.numItems);
    const [page, setPage] = useState(props.page);

    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            props.onUpdate(page);
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        setPage(props.page);
        // eslint-disable-next-line
    }, [props.page]);

    const handleChange = event => {
        setNumItemsInput(event.target.value);
    };

    const handleBlur = event => {
        props.onUpdateNumItems(parseInt(event.target.value));
        firstPage();
    };

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleBlur(event);
        }
    };

    const nextPage = event => {
        setPage(page + 1);
    };

    const prevPage = event => {
        setPage(page - 1);
    };

    const lastPage = event => {
        setPage(Math.ceil(props.totalItems / numItemsInput) - 1);
    };

    const firstPage = event => {
        setPage(0);
    };

    return (
        <div className="pagination">
            <div className="left">
                <div className="label">Zeige</div>
                <input type="text"
                       value={numItemsInput}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       onKeyPress={handleKeyPress}
                />
                <div className="label">pro Seite</div>
            </div>
            {props.totalPages > 1 &&
            <div className="right">
                <div className={"button " + (page === 0 ? "disabled" : "")} onClick={firstPage}>
                    <div className="arrow-double-icon">
                        <svg viewBox="0 0 13.16 10.97">
                            <path d="M12.5,0c0.37,0,0.67,0.3,0.67,0.67c0,0.18-0.07,0.34-0.19,0.47L8.61,5.5l4.36,4.36
	c0.24,0.28,0.22,0.7-0.06,0.94c-0.25,0.22-0.63,0.22-0.89,0L7.19,5.97c-0.26-0.26-0.26-0.68,0-0.94l4.83-4.83
	C12.15,0.07,12.32,0,12.5,0"/>
                            <path d="M5.5,0c0.37,0,0.67,0.3,0.67,0.67c0,0.18-0.07,0.34-0.19,0.47L1.61,5.5l4.36,4.36
	c0.24,0.28,0.22,0.7-0.06,0.94c-0.25,0.22-0.63,0.22-0.89,0L0.19,5.97c-0.26-0.26-0.26-0.68,0-0.94L5.03,0.2C5.15,0.07,5.32,0,5.5,0
	"/>
                        </svg>
                    </div>
                </div>
                <div className={"button " + (page === 0 ? "disabled" : "")} onClick={prevPage}>
                    <div className="arrow-icon">
                        <svg viewBox="0 0 6.16 10.97">
                            <path d="M5.5,0c0.37,0,0.67,0.3,0.67,0.67c0,0.18-0.07,0.34-0.19,0.47L1.61,5.5l4.36,4.36
	c0.24,0.28,0.22,0.7-0.06,0.94c-0.25,0.22-0.63,0.22-0.89,0L0.19,5.97c-0.26-0.26-0.26-0.68,0-0.94L5.03,0.2C5.15,0.07,5.32,0,5.5,0"/>
                        </svg>
                    </div>
                </div>
                <div className="label">{page + 1}/{props.totalPages}</div>
                <div className={"button " + (page < props.totalPages - 1 ? "" : "disabled")} onClick={nextPage}>
                    <div className="arrow-icon">
                        <svg viewBox="0 0 6.16 10.97">
                            <path d="M0.67,0c0.18,0,0.35,0.07,0.47,0.2l4.83,4.83c0.26,0.26,0.26,0.68,0,0.94L1.14,10.8
	c-0.25,0.22-0.63,0.22-0.89,0c-0.28-0.24-0.3-0.67-0.06-0.94L4.55,5.5L0.19,1.14C0.07,1.02,0,0.85,0,0.67C0,0.31,0.3,0.01,0.67,0"/>
                        </svg>
                    </div>
                </div>
                <div className={"button " + (page < props.totalPages - 1 ? "" : "disabled")} onClick={lastPage}>
                    <div className="arrow-double-icon">
                        <svg viewBox="0 0 13.16 10.97">
                            <path d="M0.67,0c0.18,0,0.35,0.07,0.47,0.2l4.83,4.83c0.26,0.26,0.26,0.68,0,0.94L1.14,10.8
	c-0.25,0.22-0.63,0.22-0.89,0c-0.28-0.24-0.3-0.67-0.06-0.94L4.55,5.5L0.19,1.14C0.07,1.02,0,0.85,0,0.67C0,0.31,0.3,0.01,0.67,0"/>
                            <path d="M7.67,0c0.18,0,0.35,0.07,0.47,0.2l4.83,4.83c0.26,0.26,0.26,0.68,0,0.94L8.14,10.8
	c-0.25,0.22-0.63,0.22-0.89,0c-0.28-0.24-0.3-0.67-0.06-0.94l4.36-4.36L7.19,1.14C7.07,1.02,7,0.85,7,0.67C7,0.31,7.3,0.01,7.67,0"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default TablePagination;