import React, {useReducer} from "react";
import * as EmailValidator from 'email-validator';

const initialState = {
    email: "",
    password: "",
    error: "",
};

function reducer(state, newState) {
    return {...state, ...newState};
}

function Login(props) {
    const [state, setState] = useReducer(reducer, initialState);

    const handleChange = event => {
        setState({
            [event.target.id]: event.target.value
        });

        setState({error: ""});
    };

    const handleSubmit = async event => {
        event.preventDefault();

        if (!EmailValidator.validate(state.email)) {
            setState({error: "email"});
        } else if (state.password.length < 1) {
            setState({error: "password-empty"});
        } else {
            let endpoint = process.env.REACT_APP_API_ENDPOINT;
            endpoint += "/user/login";

            let formData = new FormData();
            formData.append("_username", state.email);
            formData.append("_password", state.password);

            let options = {
                credentials: 'include',
                method: "POST",
                body: formData
            };
            try {
                const response = await fetch(endpoint, options);
                let json = await response.json();
                onLogin(json);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const onLogin = (data) => {
        if (data.status === "ok") {
            props.handleUserHasAuthenticated(true);
        } else {
            setState({error: data.status});
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input id="email"
                   className="textinput"
                   autoFocus
                   type="email"
                   value={state.email}
                   onChange={handleChange}
                   placeholder="E-Mail Adresse"
            />
            {state.error === 'email' &&
                <p>Bitte E-Mail Adresse eingeben</p>
            }
            <input id="password"
                   className="textinput"
                   value={state.password}
                   onChange={handleChange}
                   type="password"
                   placeholder="Passwort"
            />
            {state.error === 'failed' &&
                <p>Bitte E-Mail Adresse und Passwort prüfen</p>
            }
            {state.error === 'password-empty' &&
                <p>Passwort ist leer</p>
            }
            <button type="submit">Anmelden</button>
        </form>
    );
}

export default Login;