import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

function TableRow(props) {
    const [selected, setSelected] = useState(props.obj.selected);

    useEffect(() => {
        setSelected(props.obj.selected);
        // eslint-disable-next-line
    }, [props.obj.selected]);

    return (
        <>
            {(!props.link && props.onClick) &&
                <div className="row body" onClick={(() => props.onClick(props.obj))}>
                    {
                        props.fields.map((field, index) => <div key={index} className={"cell " + field.className}>{field.label}</div>)
                    }
                </div>
            }
            {(!props.link && !props.onClick) &&
                <div className="row body noclick">
                    {
                        props.fields.map((field, index) => <div key={index} className={"cell " + field.className + (selected ? " selected" : " omitted")}>{field.label}</div>)
                    }
                </div>
            }
            {props.link &&
                <Link to={props.link}>
                    <div className="row body">
                        {
                            props.fields.map((field, index) => <div key={index} className={"cell " + field.className}>{field.label}</div>)
                        }
                    </div>
                </Link>
            }
        </>
    );
}

export default TableRow;