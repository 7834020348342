import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {format} from "date-fns";

function Bestaetigen(props) {
    const [data, setData] = useState(null);
    const {id} = useParams()

    let endpoint = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        load();
    }, []);

    async function load() {
        let url = endpoint + "/admin/pruefen/" + id;

        let options = {
            credentials: 'include'
        };
        try {
            const response = await fetch(url, options);
            let json = await response.json();
            onData(json);
        } catch (error) {
            console.log(error);
        }
    }

    async function handleStatus(status) {
        let url = endpoint + "/admin/status/" + id + "/" + status;

        let options = {
            credentials: 'include'
        };
        try {
            const response = await fetch(url, options);
            let json = await response.json();
            onStatus(json);
        } catch (error) {
            console.log(error);
        }
    }

    const renderStatus = () => {
        switch (data.status) {
            case 'new':
                return "Neu";
            case 'confirmed':
                return "Bestätigt";
            case 'approved':
                return "Freigegeben";
            case 'deleted':
                return "Gelöscht";
            case 'offline':
                return "Offline";
        }
    }

    const onData = (data) => {
        setData(data);
    }

    const onStatus = () => {
        load();
    }

    return (
        <div className="page page-test">
            {data &&
                <div className="container">
                    <h1>Beitrag prüfen</h1>
                    <div className="columns">
                        <div className="column-left">
                            <p>
                                <strong>
                                    ID {data.id} ({renderStatus()})<br/>
                                    {format(new Date(data.createdAt.date), 'dd.MM.yyyy · HH:mm', {})} Uhr
                                </strong>
                            </p>

                            <img src={endpoint + '/uploads/' + data.filename} alt=""/>
                        </div>
                        <div className="column-right">
                            <div className="name">
                                <p>
                                    <strong>
                                        {data.firstname}<br/>
                                        {data.lastname}<br/>
                                        {data.email}<br/>
                                    </strong>
                                </p>
                            </div>
                            <p>
                                <strong>Frage 1</strong><br/>
                                {data.q1}<br/>
                                <strong>Antwort: {data.a1 === true ? "Ja" : "Nein"}</strong>
                            </p>
                            <p>
                                <strong>Frage 2</strong><br/>
                                {data.q2}<br/>
                                <strong>Antwort: {data.a2 === true ? "Ja" : "Nein"}</strong>
                            </p>
                            <p>
                                <strong>Frage 3</strong><br/>
                                {data.q3}<br/>
                                <strong>Antwort: {data.a3 === true ? "Ja" : "Nein"}</strong>
                            </p>

                            <div className="buttons">
                                <div className="button loeschen" onClick={() => handleStatus('deleted')}>Löschen</div>
                                {data.status === 'approved'
                                    ? <div className="button offline" onClick={() => handleStatus('offline')}>Offline</div>
                                    : <div className="button freigeben" onClick={() => handleStatus('approved')}>Freigeben</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Bestaetigen;